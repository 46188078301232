<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr45_hero.png" alt="National Partners Adds Input 1's Technology Stack">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr45_small.png" alt="National Partners Adds Input 1's Technology Stack">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">National Partners Adds Input 1's Technology Stack to their Roster to Streamline Operations for Insurance Agents and Carriers</h2>
                        <br>
                        <p><em><small>December 5th, 2024</small></em></p>
                        <br>
                        <p><strong>Denver, CO</strong> -- <a @click="clickOutWarning('https://www.nationalpartners.com/');">National Partners</a>, a nation-wide premium finance company, announced today its strategic partnership with <a @click="clickOutWarning('http://www.input1.com/');">Input 1</a>. To further accelerate its ever-growing business, National Partners was seeking a strong alliance to provide robust, modern billing and payment solutions to support the company’s trajectory. The partnership will leverage two of Input 1's cutting-edge solutions: PBS, a robust premium finance management system, and Input 1 Payments, an innovative digital payment processing platform. These solutions are expected to significantly enhance operational efficiency and customer engagement for National Partners, granting them the ability to expand service offerings to agents, policyholders, and carriers, while providing greater flexibility and efficiency in the process.</p>
                        <p>“Our partnership with National Partners is a pivotal step in our strategy to unite game-changing solutions with outstanding premium finance organizations,” said Todd Greenbaum, CEO of Input 1. “We are thrilled to embark on this journey with National Partners and deliver reliable solutions that will help agents’ and carriers achieve greater efficiency in their day-to-day operations.”</p>
                        <p>As one of the top premium finance companies in the industry and a wholly-owned subsidiary of <a href="/">WebBank</a> (a Steel Partners Company), National Partners sought out a solutions provider that could deliver industry leading features and services in a scalable manner.</p>
                        <p>“Adding Input 1 solutions to our roster was a necessary next step in expanding our business,” said Bert Irigoyen, President & CEO of National Partners. “We were looking for a provider that has a successful track record of providing an exceptional customer experience while streamlining user experience and increasing efficiency. Input 1’s technology platforms meet these needs and more.”</p>
                        <p>The Input 1 PBS platform offers a modern overall experience for policyholders, brokers and insurance carriers and includes responsive technical support that answers questions and/or concerns in minutes or hours, not days or weeks. The platform also gives National Partners’ users access to automation including automatic delivery of notices and reports delivered to client inboxes, whenever and wherever they want it, streamlining operations, creating a great customer experience, and ensuring the accuracy of the information right out of the gate.</p>



                        <br>
                        <p><strong>About National Partners</strong></p>
                        <p>Dedicated to providing the highest standards in the premium finance industry, National Partners, a wholly owned subsidiary of WebBank, works with clients of all sizes through its commercial insurance agent and broker relationships. Responsive service, ease of use and creative solutions are its hallmarks. It also offers funding programs to third-party premium finance companies, often those affiliated with agents, brokers, and MGAs, so that these smaller premium finance companies can access capital to grow and expand. For more information, please visit <a @click="clickOutWarning('https://www.nationalpartners.com/');">www.nationalpartners.com</a>.</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p><a href="/">WebBank</a> is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $195 billion in consumer and commercial credit products. As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies. The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners. For more information, please visit <a href="https://webbank.com">webbank.com</a>.</p>
                        <br>
                        <p><strong>About Input 1</strong></p>
                        <p>Input 1 is a leading provider of tech-enabled solutions for the insurance industry with a proven track record of successfully managing $16 billion in annual insurance premiums across platforms for over 2 million unique annual users. Input 1's solutions streamline billing, payments, and premium finance to make insurance management efficient and hassle-free.  For more information, please visit <a @click="clickOutWarning('https://www.input1.com/');">www.input1.com</a>.</p>
                        <br>

                        <p><strong>Media Contact:</strong>
                            <br>Madison Kerr, Interdependence Public Relations
                            <br>(607)-373-0811
                            <br><a href="mailto:madisonk@interdependence.com">madisonk@interdependence.com</a>
                        </p>
                        <br>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "National Partners Adds Input 1's Technology Stack";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
