<template>
    <section class="hero wb-hero is-hidden-touch">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-4-desktop has-text-right">
                            <div class="hero-box-right">
                                <div class="columns">
                                    <div class="column is-6 is-offset-6">
                                        <p class="title hero-title">
                                            The Bank<br>Behind The Brand
                                        </p>
                                        <p class="subtitle hero-subtitle">
                                            As the Bank Behind The Brand<sup>&reg;</sup>, we empower
                                            fintech partners to drive innovation and financial inclusion.
                                        </p>
                                        <br>
                                        <router-link to="/become-partner" class="button is-primary is-large"
                                            title="Partner with WebBank">Partner With Us</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-desktop">
        <img src="https://assets.webbank.com/wb-hero-mobile.png" alt="Welcome to WebBank!">

        <section class="hero wb-hero-mobile">
            <div class="hero-body">
                <section class="section">
                    <div class="container">
                        <div class="columns">
                            <div class="column is-8 is-offset-4-desktop">
                                <p class="title hero-title-mobile">
                                    The Bank<br>Behind The Brand
                                </p>
                                <p class="subtitle hero-subtitle-mobile">
                                    As the Bank Behind The Brand<sup>&reg;</sup>, we empower
                                    fintech partners to drive innovation and financial inclusion.
                                </p>
                                <br>
                                <router-link to="/become-partner" class="button is-white"
                                    title="Partner with WebBank">Partner With Us</router-link>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </section>
    </div>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <br>
                    <img src="https://assets.webbank.com/wb-home2.png" class="body-image"
                        alt="WebBank Strategic Partnerships" />
                </div>
                <div class="column is-5">
                    <br>
                    <h3 class="is-size-1 has-text-primary section-title">Strong Partnerships.<br>Great Solutions.</h3>
                    <br>
                    <p>WebBank is a national issuer of consumer and small business credit products
                        through Strategic
                        Partner platforms, which include retailers, manufacturers, finance companies, software as a
                        service (“SaaS”) and financial technology (fintech) companies. We are a leading player in the
                        digital lending space, driving innovation in financial products through embedded finance with
                        Strategic Partner platforms. </p>
                </div>
            </div>
        </div>
    </section>
    <br>
    <div id="differentiators" class="blue-bg">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="has-text-white hero-title is-hidden-mobile">KEY DIFFERENTIATORS</h3>
                    <h3 class="is-size-3 has-text-white section-title is-hidden-desktop">KEY DIFFERENTIATORS</h3>
                </div>
                <br>
                <div class="tile is-ancestor">
                    <div class="tile is-vertical">
                        <div class="tile">
                            <div class="tile is-parent">
                                <article class="tile is-child box has-text-centered">
                                    <br>
                                    <br>
                                    <span class="icon diff-icon"><i
                                            class="fa-light fa-hand-holding-hand fa-4x"></i></span>
                                    <br>
                                    <br>
                                    <h4 class="box-title">Collaborative</h4>
                                    <p class="is-size-5">We take a consultative approach that bridges innovation and
                                        regulatory requirements to drive product and funding solutions.</p>
                                </article>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child box has-text-centered">
                                    <br>
                                    <br>
                                    <span class="icon diff-icon"><i class="fa-light fa-seedling fa-4x"></i></span>
                                    <br>
                                    <br>
                                    <h4 class="box-title">Growth Oriented</h4>
                                    <p class="is-size-5">Our entrepreneurial spirit together with our
                                        institutional knowledge power growth and scale
                                        with partners that range from start-ups to Fortune
                                        500 companies.</p>
                                </article>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child box has-text-centered">
                                    <br>
                                    <br>
                                    <span class="icon diff-icon"><i class="fa-light fa-lightbulb-on fa-4x"></i></span>
                                    <br>
                                    <br>
                                    <h4 class="box-title">Innovative</h4>
                                    <p class="is-size-5">We continuously partner with platforms to incubate
                                        and launch new products through the digitization of
                                        financial services.</p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br><br>
    </div>


    <div id="extraordinary">
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-5 is-offset-1 has-text-right-desktop">
                        <h3 class="is-size-1 has-text-primary section-title">Extraordinary Brands Partner with WebBank.
                        </h3>
                        <br>
                        <p>Our partners represent some of the most successful start-ups and some
                            of the most recognizable brands representing a diverse mix of businesses. We work closely
                            with each of our partners to develop solutions that expand access to capital and financial
                            products. </p>
                        <br>
                        <router-link to="/brand-partners" class="button is-primary is-large">See Our
                            Partners</router-link>
                    </div>
                    <div class="column is-5">
                        <img src="https://assets.webbank.com/414.png" class="body-image" alt="WebBank Brand Partners" />
                    </div>
                </div>
            </div>
        </section>
    </div>

    <br>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <img src="https://assets.webbank.com/R5_C1557.png" class="body-image"
                        alt="WebBank Specialty Finance" />
                </div>
                <div class="column is-5">
                    <h3 class="is-size-1 has-text-primary section-title">Access to Capital to Fund Specialty Finance
                        Assets</h3>
                    <br>
                    <p>WebBank provides asset-based lending and other credit facilities to Strategic
                        Partners, credit funds, and other lenders with a focus on specialty finance assets. </p>
                    <br>
                    <router-link to="/asset-finance" class="button is-primary is-large">Learn More</router-link>
                </div>
            </div>
        </div>
    </section>
    <br>

    <div id="newsroom">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="has-text-white hero-title is-hidden-mobile">News</h3>
                    <h3 class="is-size-3 has-text-white section-title is-hidden-desktop">News</h3>
                </div>
                <br>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR45-2024-12-05">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr45_small.png" alt="National Partners Adds Input 1's Technology Stack">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">National Partners Adds Input 1's Technology Stack to their Roster to Streamline Operations for Insurance Agents and Carriers</p>
                        <br>
                        <br>
                        <time datetime="2024-08-06" class="news-date">December 5th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR44-2024-08-06">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr44_small.png" alt="ADDITION OF MALIN ERIKSSON TO WEBBANK BOARD OF DIRECTORS">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">BMG Money and WebBank Join Forces to Expand Financial Inclusion Nationwide</p>
                        <br>
                        <br>
                        <time datetime="2024-08-06" class="news-date">August 6th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR42-2024-06-04">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr42_small.png" alt="ADDITION OF MALIN ERIKSSON TO WEBBANK BOARD OF DIRECTORS">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Announces the Addition of Malin Eriksson to its Board of Directors</p>
                        <br>
                        <br>
                        <time datetime="2024-06-04" class="news-date">June 4th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

                <br>

                <div class="has-text-centered">
                    <h3 class="is-size-3 has-text-white section-title">
                        <router-link to="/newsroom" class="has-text-white" title="WebBank Newsroom">
                            <span>Read More from the Newsroom</span>&nbsp;&nbsp;
                            <span class="icon"><i class="fas fa-arrow-right fa-sm"></i></span>
                        </router-link>
                    </h3>
                </div>
                <br>
                <br>
            </div>
        </section>
    </div>

    <CareersHero parent="home"></CareersHero>


</template>

<script>
import CareersHero from '@/components/CareersHero.vue';
import { useNavbarStore } from '@/stores/navbar';

export default {
    components: { CareersHero },
    setup() {
        const store = useNavbarStore();
        return { store };
    },
    created() {
        document.title = "Home - WebBank";
    },
};
</script>
