<template>
  <navbar-component :show_fdic="show_fdic"></navbar-component>
      <router-view></router-view>
      <site-footer></site-footer>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import NavbarComponent from './components/NavbarComponent.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    NavbarComponent,
    SiteFooter
  },

  setup() {
    const show_fdic = ref(false);
    const location = useRoute();

    const pages_with_fdic = [
      '/',
      '/deposit-products',
      '/time-products',
      '/savings',
      '/rates-fees',
      '/forms',
      '/security',
    ]
    const showFdic = () => {
      // Your logic to determine visibility based on route.path
      console.log('LOCATION: ' + location.path);
      show_fdic.value = pages_with_fdic.includes(location.path);
    };

    onMounted(showFdic); // Check visibility on initial load

    watch(location, () => {
      showFdic();
    });
    return { location, show_fdic };
  }
}
</script>

<style>
</style>
