import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import HomeTwo from "@/views/HomeTwo.vue";
import HomeThree from "@/views/HomeThree.vue";
import AboutPage from "@/views/AboutPage.vue";
import FormsPage from "@/views/FormsPage.vue";
import RatesPage from "@/views/RatesPage.vue";
import PartnerPage from "@/views/PartnerPage.vue";
import BrandPartnersPage from "@/views/BrandPartnersPage.vue";
import BusinessPartnersPage from "@/views/BusinessPartnersPage.vue";
import AssetFinancePage from "@/views/AssetFinancePage.vue";
import DepositProductsPage from "@/views/DepositProductsPage.vue";
import TimeDepositsPage from "@/views/TimeDepositsPage.vue";
import SavingsPage from "@/views/SavingsPage.vue";
import BankingSecurityPage from "@/views/BankingSecurityPage.vue";
import CommercialInsurancePage from "@/views/CommercialInsurancePage.vue";
import OurPeoplePage from "@/views/OurPeoplePage.vue";
import ContactUsPage from "@/views/ContactUsPage.vue";
import CareersPage from "@/views/CareersPage.vue";
import PrivacyPage from "@/views/PrivacyPage.vue";
import TermsPage from "@/views/TermsPage.vue";
import TermsAndConditionsPage from "@/views/TermsAndConditionsPage.vue";
import PatriotActPage from "@/views/PatriotActPage.vue";
import UigeaPage from "@/views/UigeaPage.vue";
import BusinessAssociationsPage from "@/views/BusinessAssociationsPage.vue";
import NewsroomPage from "@/views/NewsroomPage.vue";
import NewsroomPage2 from "@/views/NewsroomPage2.vue";
import PR1Page from "@/views/Newsroom/PR1-061722.vue";
import PR2Page from "@/views/Newsroom/PR2-061522.vue";
import PR3Page from "@/views/Newsroom/PR3-061322.vue";
import PR4Page from "@/views/Newsroom/PR4-060122.vue";
import PR5Page from "@/views/Newsroom/PR5-051022.vue";
import PR6Page from "@/views/Newsroom/PR6-041422.vue";
import PR7Page from "@/views/Newsroom/PR7-041322.vue";
import PR8Page from "@/views/Newsroom/PR8-041322.vue";
import PR9Page from "@/views/Newsroom/PR9-071422.vue";
import PR10Page from "@/views/Newsroom/PR10-072322.vue";
import PR11Page from "@/views/Newsroom/PR11-080522.vue";
import PR12Page from "@/views/Newsroom/PR12-081022.vue";
import PR13Page from "@/views/Newsroom/PR13-081622.vue";
import PR14Page from "@/views/Newsroom/PR14-090622.vue";
import PR15Page from "@/views/Newsroom/PR15-081922.vue";
import PR16Page from "@/views/Newsroom/PR16-11022022.vue";
import PR17Page from "@/views/Newsroom/PR17-102022.vue";
import PR18Page from "@/views/Newsroom/PR18-11202022.vue";
import PR19Page from "@/views/Newsroom/PR19-12072022.vue";
import PR20Page from "@/views/Newsroom/PR20-01212023.vue";
import PR21Page from "@/views/Newsroom/PR21-01212023.vue";
import PR22Page from "@/views/Newsroom/PR22-01272023.vue";
import PR23Page from "@/views/Newsroom/PR23-03312023.vue";
import PR25Page from "@/views/Newsroom/PR25-04242023.vue";
import PR26Page from "@/views/Newsroom/PR26-05162023.vue";
import PR27Page from "@/views/Newsroom/PR27-05182023.vue";
import PR28Page from "@/views/Newsroom/PR28-05222023.vue";
import PR29Page from "@/views/Newsroom/PR29-05272023.vue";
import PR30Page from "@/views/Newsroom/PR30-06112023.vue";
import PR31Page from "@/views/Newsroom/PR31-07262023.vue";
import PR32Page from "@/views/Newsroom/PR32-07262023.vue";
import PR33Page from "@/views/Newsroom/PR33-09262023.vue";
import PR34Page from "@/views/Newsroom/PR34-10022023.vue";
import PR35Page from "@/views/Newsroom/PR35-01282024.vue";
import PR36Page from "@/views/Newsroom/PR36-02272024.vue";
import PR37Page from "@/views/Newsroom/PR37-03272024.vue";
import PR38Page from "@/views/Newsroom/PR38-03272024.vue";
import PR39Page from "@/views/Newsroom/PR39-04092024.vue";
import PR40Page from "@/views/Newsroom/PR40-04172024.vue";
import PR41Page from "@/views/Newsroom/PR41-06172024.vue";
import PR42Page from "@/views/Newsroom/PR42-06172024.vue";
import PR43Page from "@/views/Newsroom/PR43-06172024.vue";
import PR44Page from "@/views/Newsroom/PR44-08122024.vue";
import PR45Page from "@/views/Newsroom/PR45-12102024.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
    },
    {
        path: "/home-two",
        name: "HomeTwo",
        component: HomeTwo,
    },
    {
        path: "/home-three",
        name: "HomeThree",
        component: HomeThree,
    },
    {
        path: "/savings",
        name: "SavingsPage",
        component: SavingsPage,
    },
    {
        path: "/uigea",
        name: "UigeaPage",
        component: UigeaPage,
    },
    {
        path: "/terms",
        name: "TermsPage",
        component: TermsPage,
    },
    {
        path: "/terms-and-conditions",
        name: "TermsAndConditionsPage",
        component: TermsAndConditionsPage,
    },
    {
        path: "/us-patriot-act",
        name: "PatriotActPage",
        component: PatriotActPage,
    },
    {
        path: "/privacy",
        name: "PrivacyPage",
        component: PrivacyPage,
    },
    {
        path: "/working-at-webbank",
        name: "CareersPage",
        component: CareersPage,
    },
    {
        path: "/contact-us",
        name: "ContactUsPage",
        component: ContactUsPage,
    },
    {
        path: "/our-people",
        name: "OurPeoplePage",
        component: OurPeoplePage,
    },
    {
        path: "/become-partner",
        name: "PartnerPage",
        component: PartnerPage,
    },
    {
        path: "/commercial-insurance",
        name: "CommercialInsurancePage",
        component: CommercialInsurancePage,
    },
    {
        path: "/time-deposits",
        name: "TimeDepositsPage",
        component: TimeDepositsPage,
    },
    {
        path: "/business-brand-partners",
        name: "BusinessBrandPartnersPage",
        component: BusinessPartnersPage,
    },
    {
        path: "/brand-partners",
        name: "BrandPartnersPage",
        component: BrandPartnersPage,
    },
    {
        path: "/security",
        name: "BankingSecurityPage",
        component: BankingSecurityPage,
    },
    {
        path: "/deposit-products",
        name: "DepositProductsPage",
        component: DepositProductsPage,
    },
    {
        path: "/asset-finance",
        name: "AssetFinancePage",
        component: AssetFinancePage,
    },
    {
        path: "/about",
        name: "AboutPage",
        component: AboutPage,
    },
    {
        path: "/forms",
        name: "FormsPage",
        component: FormsPage,
    },
    {
        path: "/rates-fees",
        name: "RatesPage",
        component: RatesPage,
    },
    {
        path: "/business-associations",
        name: "BusinessAssociationsPage",
        component: BusinessAssociationsPage,
    },
    {
        path: "/newsroom",
        name: "NewsroomPage",
        component: NewsroomPage,
    },
    {
        path: "/newsroom2",
        name: "NewsroomPage2",
        component: NewsroomPage2,
    },
    {
        path: "/newsroom/capital-on-tap-2022-06-17",
        name: "PR1Page",
        component: PR1Page,
    },
    {
        path: "/newsroom/paypal-2022-06-15",
        name: "PR2Page",
        component: PR2Page,
    },
    {
        path: "/newsroom/icreditworks-2022-06-13",
        name: "PR3Page",
        component: PR3Page,
    },
    {
        path: "/newsroom/klarna-2022-06-01",
        name: "PR4Page",
        component: PR4Page,
    },
    {
        path: "/newsroom/dignifi-2022-05-10",
        name: "PR5Page",
        component: PR5Page,
    },
    {
        path: "/newsroom/gemini-2022-04-14",
        name: "PR6Page",
        component: PR6Page,
    },
    {
        path: "/newsroom/icreditworks-2022-04-13",
        name: "PR7Page",
        component: PR7Page,
    },
    {
        path: "/newsroom/capital-on-tap-2022-04-13",
        name: "PR8Page",
        component: PR8Page,
    },
    {
        path: "/newsroom/avant-PR9-2022-07-14",
        name: "PR9Page",
        component: PR9Page,
    },
    {
        path: "/newsroom/lane-health-PR10-2022-07-20",
        name: "PR10Page",
        component: PR10Page,
    },
    {
        path: "/newsroom/most-innovative-PR11-2022-07-21",
        name: "PR11Page",
        component: PR11Page,
    },
    {
        path: "/newsroom/security-premium-finance-PR12-2022-08-04",
        name: "PR12Page",
        component: PR12Page,
    },
    {
        path: "/newsroom/american-express-PR13-2022-08-16",
        name: "PR13Page",
        component: PR13Page,
    },
    {
        path: "/newsroom/ft-ceo-interview-PR14-2022-09-06",
        name: "PR14Page",
        component: PR14Page,
    },
    {
        path: "/newsroom/lane-health-burnham-flower-PR15-2022-08-19",
        name: "PR15Page",
        component: PR15Page,
    },
    {
        path: "/newsroom/zip-card-PR16-2022-10-25",
        name: "PR16Page",
        component: PR16Page,
    },
    {
        path: "/newsroom/capital-on-tap-PR17-2022-10-20",
        name: "PR17Page",
        component: PR17Page,
    },
    {
        path: "/newsroom/collective-liquidity-PR18-2022-11-15",
        name: "PR18Page",
        component: PR18Page,
    },
    {
        path: "/newsroom/avant-funding-PR19-2022-12-07",
        name: "PR19Page",
        component: PR19Page,
    },
    {
        path: "/newsroom/kelsey-hardy-PR20-2023-01-20",
        name: "PR20Page",
        component: PR20Page,
    },
    {
        path: "/newsroom/glassdoor-PR21-2023-01-20",
        name: "PR21Page",
        component: PR21Page,
    },
    {
        path: "/newsroom/glassdoor-PR22-2023-01-27",
        name: "PR22Page",
        component: PR22Page,
    },
    {
        path: "/newsroom/libertas-funding-PR23-2023-03-31",
        name: "PR23Page",
        component: PR23Page,
    },
    {
        path: "/newsroom/can-capital-PR25-2023-04-24",
        name: "PR25Page",
        component: PR25Page,
    },
    {
        path: "/newsroom/onemain-financial-PR26-2023-05-16",
        name: "PR26Page",
        component: PR26Page,
    },
    {
        path: "/newsroom/petal-raise-PR27-2023-05-18",
        name: "PR27Page",
        component: PR27Page,
    },
    {
        path: "/newsroom/lane-health-apex-PR28-2023-05-22",
        name: "PR28Page",
        component: PR28Page,
    },
    {
        path: "/newsroom/build-PR29-2023-05-23",
        name: "PR29Page",
        component: PR29Page,
    },
    {
        path: "/newsroom/webbank-PR30-2023-06-11",
        name: "PR30Page",
        component: PR30Page,
    },
    {
        path: "/newsroom/webbank-PR31-2023-07-26",
        name: "PR31Page",
        component: PR31Page,
    },
    {
        path: "/newsroom/webbank-PR32-2023-07-26",
        name: "PR32Page",
        component: PR32Page,
    },
    {
        path: "/newsroom/webbank-PR33-2023-09-12",
        name: "PR33Page",
        component: PR33Page,
    },
    {
        path: "/newsroom/webbank-PR34-2023-10-02",
        name: "PR34Page",
        component: PR34Page,
    },
    {
        path: "/newsroom/webbank-PR35-2024-01-29",
        name: "PR35Page",
        component: PR35Page,
    },
    {
        path: "/newsroom/webbank-PR36-2024-02-27",
        name: "PR36Page",
        component: PR36Page,
    },
    {
        path: "/newsroom/webbank-PR37-2024-03-27",
        name: "PR37Page",
        component: PR37Page,
    },
    {
        path: "/newsroom/webbank-PR38-2024-03-27",
        name: "PR38Page",
        component: PR38Page,
    },
    {
        path: "/newsroom/webbank-PR39-2024-04-09",
        name: "PR39Page",
        component: PR39Page,
    },
    {
        path: "/newsroom/webbank-PR40-2024-04-17",
        name: "PR40Page",
        component: PR40Page,
    },
    {
        path: "/newsroom/webbank-PR41-2024-05-22",
        name: "PR41Page",
        component: PR41Page,
    },
    {
        path: "/newsroom/webbank-PR42-2024-06-04",
        name: "PR42Page",
        component: PR42Page,
    },
    {
        path: "/newsroom/webbank-PR43-2024-05-16",
        name: "PR43Page",
        component: PR43Page,
    },
    {
        path: "/newsroom/webbank-PR44-2024-08-06",
        name: "PR44Page",
        component: PR44Page,
    },
    {
        path: "/newsroom/webbank-PR45-2024-12-05",
        name: "PR45Page",
        component: PR45Page,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        }
        // always scroll to top
        return { top: 0 }
    },
});

export default router;
